<template>
  <div>
  
    <the-modal class="col-lg-8 offset-lg-2" v-show="videoActive">
      <b-icon-x id="close-x" class="d-none d-lg-block" @click="closeEverything" style="position: absolute; color: white; z-index: 150; right: -50px; height: 35px; width: 35px; opacity: .5;"></b-icon-x>
      <video
        class="embed-responsive embed-responsive-16by9 video"
        controls
        ref="indexVideo" 
        src="https://unityworks-a.akamaihd.net/anon.unityworks/2/3/K/48884091_Digital_Dealer_Program_2.0_230221b_230221102527_4.mp4"
        type='video/mp4'>
      </video>
    </the-modal>

    <div class="d-flex justify-content-center h-100 content">
      <b-row class="w-100" style="max-width: 1200px;">

        <b-col md="12">
          <h1 class="mt-3" style="font-size: 25px; font-weight: 600;">Acura Digital Dealer Program 2.0</h1>
          <h2 style="font-size: 20px;">Take control of your customers' digital experience</h2>
        </b-col>

        <b-col md="8" class="mt-4">
          <div class="pr-4 mr-1 float-left" style="width: 200px;">
            <img
              @click="playVideo()"
              class="img-fluid"
              id="video-thumb"
              src="@/assets/video_thumb.jpg"
            />
            <p class="text-center">Overview Video</p>
          </div>
          <p>The COVID-19 crisis showed us how quickly customers can pivot their behavior. From groceries to takeout to home goods, nearly everything shifted to online shopping. The pandemic has amplified what we've already seen. For the last five or so years, Customers have come to expect Amazon-like experiences for everything they buy, regardless of value. They are now expecting the automotive industry to deliver the same.</p>
          <p>A successful digital program is not just about improving or expanding a dealer's website. It's about meshing the wants and needs of three core groups: OEMS, dealers, and of course, the end Customer. It's about identifying the car shopper's journey and creating a seamless experience online, offline and everywhere in between.</p>
          <p>For more information about enrollment through our portal, please see the <a href="/how-to-enroll">How to Enroll</a> section.</p>

          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Customers demand choices, and so do you.</h3>
          <p>The Acura Digital Dealer Team has worked with the Digital Dealer Council to design this digital program for all Acura dealers. The program strongly positions you to support the expected return of the automotive industry and the new digital expectations of your customers.</p>
          <p>With over 7,000 technology companies (and counting) looking for the business of automotive dealers, selecting the right one for your dealership can be a daunting task. Good news! Our team has simplified the process by vetting the best vendors in the industry. We have curated a set of proven partners and services for your dealership, while still providing you with flexibility and choice.</p>

          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Acura Digital Dealer Program 2.0</h3>
          <p>The Acura Digital Dealer Program 2.0 is unique in the industry because it has been designed to meet the specific needs of our Acura dealers. The challenges our dealers face in managing multiple vendor contracts, monthly meetings and reporting dashboards result in inefficiencies, lost time and ultimately lost revenue.</p>
          <p>We have worked diligently to solve this problem. We have selected ten well-respected vendor partners that offer a core set of digital solutions including website, digital advertising and SEO. These three core technologies are critical to the success of the seamless customer journey. Our approach packages these three core solutions from the vendor(s) of your choice. The key benefits of this bundled approach include:</p>
          
          <ul>
            <li><strong>Management simplicity</strong> - Moving from three siloed vendors to one integrated vendor reduces the time personnel spends managing these three marketing activities.</li>
            <li><strong>Holistic integrated digital marketing strategy and planning</strong> - Your advantage is centered on a single, dedicated team to help strategize your digital plan and ensure an integrated digital marketing plan.</li>
            <li><strong>More value for you</strong> - This integrated approach brings the strength and synergy of value pricing when compared to purchasing these services from three separate vendors.</li>
          </ul>

          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Innovation</h3>
          <p>The automotive digital landscape is always changing and we're always assessing not only what Customers expect today, but also in the future. We evaluate new cutting-edge products and services that are in incubation or early launch phases. This allows us to continuously enhance our digital programs and stay on the forefront of technology. Your participation is critical in validating these technologies, and we may invite you to participate in pilot testing to ensure that the new solutions produce results and are the right fit for Acura dealers.</p>

          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Transparency</h3>
          <p>As true partners, we put our money where our mouth is. We provide full visibility into vendor selection, performance data and financials. Ultimately, we want complete alignment with the goals and objectives of Acura and its dealers in order to sell more vehicles, parts and services. </p>

          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Dedicated Dealer Support </h3>
          <p>You will not have to take this journey on your own. Acura Digital Dealer Program 2.0 has a dedicated team of support agents and in-field digital consultants to help you navigate your digital ecosystem. Our team of consultants, who are leading experts in the industry, support dealers within designated marketing areas, including: website, digital advertising, social media, reputation management, as well as tools such as chat, service schedulers, finance and trade-in. In addition to their online digital acumen, they are well versed in lead process management and will be there to partner with your team every step of the way.</p>
 
        </b-col>

        <b-col class="text-center" md="4">
          <!-- <div>
            <h4 style="margin-bottom: 0px; margin-top: 20px;">Meet Your Team</h4>
            <p><a @click="openPdf" href="#">View Details (PDF)</a></p>
            <b-img
              fluid
              @click="openPdf"
              src="@/assets/meet_the_team_map.jpg"
              style=""
              class="team-hover"
            />
          </div> -->
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>

import TheModal from '@/components/TheModal.vue'
import { BIconX } from 'bootstrap-vue'

export default {
  name: 'about',
  components: {
    TheModal,
    BIconX
  },

  metaInfo: {
    title: 'About'
  },

  data() {
    return {
      formErrors: {},
    };
  },

  watch: {
    $route: "fetchData",
    videoActive: function() {
      if (!this.videoActive) {
        // this.resetSupportForm();
        this.stopVideo();
      }
    }
  },

  methods: {
    playVideo() {
      this.indexVideo.play();
      this.$store.commit("toggleModal");
      this.$store.commit("toggleVideo");
    },

    stopVideo() {
      this.indexVideo.pause();
      this.indexVideo.currentTime = 0;
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

    openPdf() {
      // this needs to be replaced by uploading a PDF through the dashboard. I made a dummy vendor, uploaded a PDF and then deleted the dummy vendor.
      window.open('https://hondaprogram.com/files/meet_the_team_pdf_default_1673625161.pdf');
    }
  },

  computed: {
    videoActive() {
      return this.$store.state.videoActive ? true : false;
    },

    indexVideo() {
      return this.$refs.indexVideo;
    },
  },
}



</script>

<style scoped>

  #close-x:hover {
    cursor: pointer;
    opacity: 1;
  }

  .photo {
    margin-right: 35px;
    padding: 10px;
  }

  .content {
    margin-top: 20px;
  }

  .team-hover:hover {
    filter: brightness(1.1);
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.05s ease;
  }

  @media screen and (min-width: 1400px) {

    .video {
      position: absolute;
      z-index: 250;
      background-color: black;
      border-radius: 5px;
    }

    .video:focus {
      outline: none;
    }

    #video-thumb:hover {
      opacity: 0.75;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1399px) {

    .video {
      position: absolute;
      z-index: 250;
      background-color: black;
      border-radius: 5px;
    }

    .video:focus {
      outline: none;
    }

    #video-thumb:hover {
      opacity: 0.75;
      cursor: pointer;
    }

  }

  @media screen and (max-width: 768px) {

    .content {
      margin-top: 0px;
      padding-top: 60px;
    }

    .photo {
      margin-right: 0px;
    }

    .video {
      position: fixed;
      right: 0px;
      border-radius: 0px;
      margin-top: 25%;
      z-index: 400;
    }

  }

</style>